/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
	margin: 0%;
	width: 100%;
	height: 100%;
	font-family: Verdana;
}
/**************NavBar****************/
.masterLogo {
	/* height: 89px;
	width:815px; */
	float: right;
}
img {
	float: right;
	/* width: 600px;
	height: 85px; */
	/* padding-top: 10px; */
}
a {
	position: relative;
	text-decoration: none;
}
.NavBarrow {
	height: 60px;
	display: flex;
	flex-direction: row;
}
#border {
	border-bottom: 5px solid #ad9442;
	width: 200px;
	padding-left: 15px;
	padding-right: 5px;
}
a.Activeheading {
	color: rgb(0, 124, 132) !important;
	font-size: 18px;
	font-family: Verdana;
	font-weight: 200;
	padding-top: 20px;
	width: fit-content;
	/* border-bottom:5px solid #ad9442 ; */
}

.userprofile {
	color: rgb(0, 124, 132);
}
.userprofiledropdown {
	color: rgb(0, 124, 132);
	height: 38px;
	min-width: fit-content;
	font-size: 20px;
	border: none;
	margin-right: 20px;
	outline: 0px;
}
a.heading {
	font-size: 18px;
	color: black !important;
	font-family: Verdana;
	font-weight: 200;
	padding-top: 20px;
}
.navbar {
	padding-left: 15px;
}

/************************ Project name /User name *****************/

.ProjectName {
	color: rgb(0, 124, 132);
	font-size: 25px;
	padding-left: 30px;
}
div.UserProfile {
	display: flex;
	flex-direction: row;
	color: rgb(0, 124, 132);
	justify-content: flex-end;

	padding-right: 10px;
}

/*************************footer***********/
.footer {
	text-align: left;
	color: white;
	font-size: 14px;
	width: 100%;
	height: 25px;
	background-color: rgb(0, 128, 128);
	padding-left: 12px;
}
@media screen and (min-height: 900px) {
	.footer {
		position: absolute;
	}
}

.copyright {
	padding-left: 5px;
}

@media only screen and (max-width: 768px) {
	.vertical {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.container-lg {
		height: 850px;
	}
}
/************************ Container *****************/

.container-fluid {
	font-family: Verdana;
	display: flex;
	flex-direction: column;
	min-height: 770px;
	max-width: 98%;
	padding: 6px;
	margin: 0% 1% 1% 1%;
	box-sizing: border-box;
	text-align: left;
	/* box-shadow:0px 0px 7px 7px rgb(233, 226, 226); */
	box-shadow: 0px 0px 10px 10px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
}

/**************index page************/
.indexcontainer {
	display: flex;
	flex-direction: column;
	min-height: 780px;
	max-width: 97%;
	padding: 10px;
	box-sizing: border-box;
	text-align: left;
	margin-bottom: 1%;
	/* box-shadow:0px 0px 7px 7px rgb(233, 226, 226); */
	box-shadow: 0px 0px 10px 10px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
}
a.linklist {
	color: rgb(0, 124, 130);
	font-size: 15px;
	text-align: left;
	font-family: Verdana;
	line-height: 35px;
}
.linklist {
	/* line-height: 1.6; */
	line-height: 200%;
	font-weight: 100;
	font-size: 16px;
	text-align: left;
	font-family: Verdana;
}
.orderlinklist {
	margin-left: 4%;
	line-height: 35px;
}
.indexheading {
	font-family: Verdana;
	color: rgb(0, 124, 130);
	font-size: 28px;
	font-weight: 200;
	padding-left: 25px;
}
.indexNavBar {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
/**************Login Page****************************/

.vertical {
	border-left: 1px solid rgb(69, 137, 137);
	height: 250px;
	margin-top: 80px;
	position: absolute;
	left: 50%;
}
.login {
	font-size: 50px;
	font-style: italic;
}
.loginImg {
	float: right;
	width: 600px;
	height: 90px;
	padding-top: 10px;
	margin-bottom: 1%;
}
.logincontainer {
	display: flex;
	flex-direction: column;
	min-height: 800px;
	max-width: 96%;
	padding: 5px;
	margin: 5% 1% 1.5% 1%;
	box-sizing: border-box;
	text-align: center;
	box-shadow: 0px 0px 7px 7px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
}
.Suppliersheading {
	color: #008080;
	font-size: 30px;
	font-weight: 400;
	margin-top: 20px;
	text-align: center;
	margin-left: 40%;
}
.Buyersheading {
	color: rgb(0, 124, 132);
	font-size: 30px;
	font-weight: 400;
	margin-left: 25%;
	margin-top: 20px;
	text-align: left;
}
.loginLabel {
	text-align: left;
	font-size: 15px;
	
	color: rgb(6, 6, 6);
}
.emailLabel{
	text-align: left;
	font-size: 15px;
	
	color: rgb(6, 6, 6);
}
.loginForm {
	text-align: right;
	margin-left: 40%;
	width: 450px;
	font-weight: 100;
	font-size: 15px;
	color: black;
}
.required::before {
	content: "*";
	color: red;
	float: left;
}
.SignUpform{
	margin-top: 20px;
	
}
.signUpLabelInputGroup{
	height:90px;
}
.ForgetloginLabel {
	color: rgb(0, 124, 132);
	font-size: 19px;
}
.ForgotPasswordEmailLabel{
	height: 65px;
}
.loginButton {
	padding-top: 0px;
}
.Loginbutton {
	color: white;
	background-color: rgb(173, 148, 66);
	border: none;
	font-size: 15px;
	width: 100%;
	margin-top: 0.8%;
	margin-bottom:2%;
	border-radius: 4px;
	padding: 5px;
}
.buyerLoginbutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: center;
	border: none;
	font-size: 15px;
	margin-top: 1.4%;
	margin-left:3%;
	width: 58%;
	border-radius: 4px;
	padding: 5px;
}
.btn-blocksubmit {
	color: white;
	background-color: rgb(173, 148, 66) !important;
	float: left;
	font-size: 20px;
	margin-right: 1%;
	width: auto;
	margin: 1%;
}
.intro {
	text-align:left;
	padding-top: 20px;
	font-family: Verdana;
	font-size: 16px;
	color: black;
	margin-right: 100px;
	line-height: 25px;
	width: 500px;
}
#BuyerloginButton {
	text-align: center;
	margin-right: 8%;
	width: 80%;
}

/************************** List Of Project *************/
.SearchLabel {
	text-align: left;
	font-weight: 200;
	font-size: 15px;
	color: #008080;
	font-family: Verdana;
}
.clearFilterLink{
	color:rgb(0, 124, 132);
	
	/* background-color: rgb(238, 230, 230); */
	border: none;
	font-size: 15px;
	width: 130px;
	height: 32px;
margin-top:25%;
margin-bottom: 10%;

	border-radius: 4px;
	padding: 3px;
}
.clearFilterLinkSupplier{
	color:rgb(0, 124, 132);
	
	/* background-color: rgb(238, 230, 230); */
	border: none;
	font-size: 15px;
	width: 130px;
	height: 32px;
	
margin-top:33px;
margin-bottom: 10%;

	border-radius: 4px;
	padding: 3px;
}
/* .searchFilter{
   background-color: rgb(182, 181, 181) !important;
 } */
.listOfProjectsearchRow {
	padding-left: 18px;
	
}
.searchInput {
	width: 600px;
}
.searchButton {
	color: white;
	background-color: rgb(0, 124, 132);
	/* float:left; */
	border: none;
	font-size: 18px;
	width:120px;
	height: 32px;
margin-top:20%;
margin-bottom: 10%;

	border-radius: 4px;
	padding: 3px;
}
.ProjectStatusbutton,
.JobStatusbutton {
	width: 100px;
	text-align: left;
}
.ProjectStatusdropdownbtn,
.JobStatusdropdownbtn {
	border: 1px solid rgb(214, 210, 210);
	border-radius: 5px;
	height: 33px;
	width: 130px;
	margin-top: 2%;
	font-weight: 100;
}
.createNewProjectJobsTables {
	min-height: 200px;
}
.createNewProject {
	color: white;
	background-color: #ad9442;
	/* float:left; */
	border: none;
	font-size: 18px;
	width:160px;
	height: 33px;
	margin-top:3%;
	margin-right: 2.5%;
	float: right;
	border-radius: 4px;
	padding: 1px;
}
.listOfProjectstable {
	min-height: 630px;
	
}
.listOfProjectstable tr {
	line-height: 10px;
	
}

.projectTitleLink {
	color: rgb(0, 124, 132);
	font-size: 15px;
	font-family: Verdana;
}
.forgetPasswordLink {
	color: rgb(0, 124, 132);
	font-size: 15px;
	font-family: Verdana;
	margin-right:67% ;
}
option {
	text-align: left;
	font-size: 10px;

	font-family: Verdana;
}

.listOfProjectstable {
	font-family: Verdana;
	text-align: left;
	font-size: 14px;
	padding-left: 15px;
	padding-right: 15px;
}
.supplierlistOfProjectsLoader{
	text-align: center;
	padding: 285px 200px 299px 200px;
}
.listOfProjectsLoader {
	text-align: center;
	padding: 285px 220px 289px 220px;
}
.tableHeading {
	background-color: rgb(0, 124, 132) !important;
	color: white;
	font-family: Verdana;
	font-size: 15px;
	vertical-align: text-bottom;
}
.listofprojectheading tr .title {
	width: 25%;
	height: 15px;
}
.listofprojectheading tr .client {
	width: 25%;
	height: 15px;
}
.sortIcon {
	float: right;
	font-size: 13px;
}

.SortIconTab {
	float: right;
	margin-right: 3%;
}
.listofprojectheading.Buyer,
.listofprojectheading.ISBN,
.listofprojectheading.status,
.listofprojectheading .ClientRefference {
	width: 15%;
	
}

tr:nth-child(even) {
	background-color: #e6ebed;
}

/********pagination***************************/

.PaginationRow {
	
	position: relative;

}

.pageSize {
	padding-left: 27px;
	height: 25px;
}

.page-item.active .page-link {
	background-color: #008080;
	color: white;
	height: 30px;
	padding: 0px;
	width: 30px;
	text-align: center;
}

.page-item .page-link {
	background-color: white;
	color: #008080;
	height: 30px;
	padding: 0px;
	width: 30px;
	text-align: center;
}

.pageSizedropdownbtn {
	border: 1px solid rgb(214, 210, 210);
	border-radius: 5px;
	height: 33px;
	width: 100px;
}
/********************* New Project *********************/
.inputGroup
{
	height: 60px;
}

.newProjectRow {
	padding-left: 20px;
	padding-right: 20px;
	
}
.NewProject {
	color: rgb(0, 124, 132);
	font-size: 25px;
	padding-left: 7px;
	padding-top: 8px;
	font-family: Verdana;
	font-weight: 300;
}
.newProjectJobs {
	color: rgb(0, 124, 132);
	font-size: 25px;
	font-family: Verdana;
	font-weight: 300;
	padding-left: 8px;
}
/* .NewProjectselectbuyer,
.NewProjectselectClient {
	width: 400px;
} */

tr:nth-child(even) {
	background-color: #e6ebed;
}

a.list {
	width: 400px;
}
.newProjectDetails {
	padding-top: 10px;
	text-align: left;
	font-family: Verdana;
	font-size: 15px;
	line-height: 20px;
}
.dateselector {
	background: transparent;
	border: none;
	border-bottom: 1px solid #000000;
	width: 200px;
	height: 30px;
}
.newProjectjobName,
.newProjectclientCode {
	width: 10%;
	padding-left: 10px;
}
.newProjectQuoteRequiredBy,
.newProjectBidsReceived,
.newProjectDeliveryDate {
	width: 7%;
	padding-left: 10px;
}
.newProjectStatus,
.newProjectAction {
	width: 5%;
	padding-left: 10px;
}
.Jobtable {
	text-align: left;
}
.Jobtablerow {
	padding-left: 17px;
	padding-right: 20px;
}
.disableUpdateProject{
	pointer-events: none;
	padding:5px 20px 0px 20px;
	
}
.disableBidCost{
	pointer-events: none;
}
.updateProjectTable {
	min-height: 200px;
}

.updateProjectJobTable tr {
	line-height: 8px;
}
.inputLabel {
	text-align: left;
	font-family: Verdana;
}
.required::before {
	content: "*";
	color: red;
	float: left;
}

.newProjectDetailsColOne {
    padding-top:15px;
	/* display: flex;
	flex-direction: column;
	justify-content: space-between; */
	height: 330px;
}
.newProjectDetailsColFour {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 342px;
    max-height: fit-content;
}
.newProjectDetailsColTwo {
	padding-top: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	line-height: 24px;
	min-height: 320px;
}
.colTwoDetails{
	padding-bottom:10px;
}
.newProjectDetailsColThree {
	/* padding-top: 30px; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height:343px;
	max-height: fit-content;
}
.date {
	border: null;
}
.newProjectsubmitButtonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
}

.newProjectCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	margin:0% 1.5% 0% 0%;
	width: 5%;
	height:30px;
	border-radius: 4px;
	padding: 2px;
}
.newProjectSavebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 18px;
	margin:0% 2% 0% 0%;
	width: 4%;
	height:30px;
	border-radius: 4px;
	padding: 2px;
}
.tableHeading {
	background-color: rgb(0, 124, 132) !important;
	color: white;
	font-family: Verdana;
	font-weight: 200;
}
.Jobtable tr {
	line-height: 25px;
}
/* .row{
padding-left:10px;
padding-right:10px;


} */
.Jobtable {
	padding-left: 30px;
	padding-right: 30px;
}
.jobNameLink,
.manageBidsLink {
	color: rgb(0, 124, 132);
}
.CreateJobSubmitButtonRow {
	margin-left: 40%;
	margin-top: 35%;

}
.newProjectcreateNewJob {
	color: white;
	background-color: #ad9442;
	float: right;
	border: none;
	font-size: 18px;
	width: 18%;
	height: 35px;
	margin-right: 1%;

	float: right;
	border-radius: 4px;
	padding-right: 5px;
}
option {
	text-align: left;
}
.sellerSpecInlineData {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 30px;
}

.InlineData {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 30px;
}
.Submitbuttonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.updateProjectsubmitButtonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.newProjectAbortProject,
.newProjectcompleteProject {
	color: white;
	background-color: rgb(173, 148, 66);
	float: left;
	border: none;
	font-size: 18px;
	width: 10%;
	margin: 1%;
	border-radius: 4px;
	font-family: Verdana;
}
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 45px;
	height: 20px;
	padding-right: 50px;
	margin-top: 20%;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;

	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: rgb(0, 124, 132);
}

input:focus + .slider {
	box-shadow: 0 0 1px rgb(0, 124, 132);
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

/******************* Manage Bid CSS **********************/

.BIDINVITATION {
	text-align: left;

}
.Heading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 100%;
	padding: 3px;

	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.BidResNotRecievedStatus {
	padding-top: 5px;
	font-size: 17px;
}
.managebidcontainer {
	display: flex;
	flex-direction: column;
	min-height: 750px;
	max-width: 96%;
	padding: 6px;
	margin: 0.5%;
	box-sizing: border-box;
	text-align: center;
	/* box-shadow:0px 0px 7px 7px rgb(233, 226, 226); */
	box-shadow: 0px 0px 10px 10px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
}

/* .ManageBidBidResponsetable{
	width: 300px;
} */
.EmptyCol {
	background: rgb(245, 245, 245) !important;
	border: none;
}
.ManageBidBidResponseTableName{
	width:370px;
}
.ManageBidBidResponseTableHeading{
	width:166px;
}
.ManageBidBidResponseTableQty1 {
	width: 300px;
}

.bidInvitationTableCell

{

    padding-top: 3px !important;

    padding-bottom: 3px !important;

    vertical-align: text-top;

}
/* .manageBidSupplierReqQuoteDropdown {
	border: 1px solid rgb(214, 210, 210);
	border-radius: 5px;
	height: 38px;
	width: 100px;
} */
.Quantitybreakuprow {
	padding-right: 0%;
	margin: 0%;
}
.inputBreakUpQuantity {
	width: 105%;
}
.qtyBreakUprow {
	width: 462px;
	text-align: left;
	
}
.supplierSelectionList{
	min-height: 240px;
	
}
.qtyBreakUpError,.supplierListErrorMsg{
	color:red;
	font-size: 15px;
	font-family: Verdana;
}
th.qty {
	width: 81%;
	height: 4px;
	color: white;
	background-color: rgb(0, 124, 132) !important;
	font-family: Verdana;
	font-weight: 200;
}
.removecol {
	color: white;
	background-color: rgb(0, 124, 132) !important;
	height: 4px;
	width: 100px;
	font-family: Verdana;
	font-weight: 200;
}
.qtyBreakupTablerow {
	min-height: 285px;
	
}
.RequestedSupplierTablerow{
	min-height: 260px;
}

.qtyBreakupTablerow tr {
	height: 3px;
	font-size: 14px;
}
.manageBidselectSupplier {
	width: 105%;
}
/* .tableheading{
  height: 50px;
  text-align: left;
} */

.bidInvitationTable thead {
	min-width: fit-content;
	width: 600px;
	height: 5px;
	color: white;
	text-align: left;
	background-color: rgb(0, 124, 132);
	font-weight: 100;
	font-family: verdana;
}
.bidResponsiveTable {
	min-height: 335px;
}
.bidResponsiveTable th {
	font-weight: 100;
	font-size: 16px;
}
.bidInvitationTableData tr {
	text-align: left;
}
th.suppliertitle {
	width: 50px;
	font-weight: 300;
	font-size: 15px;
	color: black;
	height: 25px;
	text-align: left;
}
.removeCol {
	text-align: center;
}

.remove {
	text-align: center;
	color: red;
	font-size: 18px;
	font-weight: 400;
}
.Jobtable {
	text-align: left;
}
.inputLabel {
	text-align: left;
	font-family: Verdana;
}
tr:nth-child(even) {
	background-color: #e6ebed;
}

.selectedSuppliers,
.selectedQuantity {
	width: 25%;
	text-align: left;
	margin: 0.2%;
	text-align: left;
	padding-bottom: 10px;
}
.Action {
	color: black;
}
option {
	text-align: left;
}
.selectedSuplierdropdown,
.selectedQuantitydropdown {
	width: 450px;
}
#selectedCell {
	background-color: rgb(173, 148, 66);
	color: white;
}
.manageBidCompletedprojectCancelCancelbutton{
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	width: 80px;
	margin-top:32px;
	margin-left: 310px;;
	min-width: fit-content;
	border-radius: 4px;
	height: 31px;
	
}
.manageBidCancelCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	width: 10%;
	margin-top:32px;
	min-width: fit-content;
	border-radius: 4px;
	height: 30px;
}
.plusIcon {
	font-size: 32px;
}
.iconCol {
	min-height: 20px;
	padding-bottom: 5px;
}
.PlusIconBtn {
	padding: 1px 20px 2px 20px;
}
/* .updateJobSave{
	display: flex;
	flex-direction: row;
} */
.AbortJobbutton {
	color: white;
	background-color: rgb(173, 148, 66);
	border: none;
	font-size: 18px;
	min-width: fit-content;
	margin-right: 7%;
	width: 3.6%;
	height: 30px;
	border-radius: 4px;
	padding: 0px 10px 0px 10px;
	
}
.manageBidSelectQtyDropDown,.manageBidSelectSupplierDropDown{
	width:400px
}
.manaageBidCompletebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: left;
	border: none;
	font-size: 18px;
	min-width: fit-content;
	width: 12%;
	margin-top: 32px;
	height: 30px;
	margin-left: 4%;
	margin-right: 3.1%;
	border-radius: 4px;
	
}
.BitInvCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	width: 20%;
	height: 30px;
	min-width: fit-content;
	border-radius: 4px;
	/* margin-top: 5px; */
}
.BitInvSavebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 18px;
	min-width: fit-content;
	width: 20%;
	height:30px;
	margin-right: 20px;
	/* margin-top: 5px; */
	border-radius: 4px;
	/* padding: 2px; */
}
.SelectedSupplierAndQuantityCol{
	min-height: 270px;
}
.manageBidsubmitButtonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.BitInvsubmitButtonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
label {
	font-size: 15px;
	font-weight:700;
	font-family: Verdana;
}
.isIsbnLabel{
	font-size: 15px;
}
/*************************** New Job*******************************/

.InlineData {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	line-height: 30px;
}
.finishedSizedropdown {
	border: 1px solid rgb(214, 210, 210);
	border-radius: 5px;
	height: 36px;
	width: 60px;
}
.dateInlineData {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	line-height: 15px;
}


.required::before {
	content: "*";
	color: red;
	float: left;
}
.Majorrow {
	padding-top: 8px;
	margin-bottom: 1.5%;
}
/* .dropdownbtn {
	border: 1px solid rgb(188, 197, 197);
	border-radius: 5px;
	height: 34px;
	width: 440px;
} */
.newJobGeneral {
	
	text-align: left;
	font-family: Verdana;
	font-size: 14px;
	line-height:19px;
	
	
}
.newJobSepcflex-item {
	padding-bottom: 25px;
}
.newJobDeliveryflex-item {
	padding-bottom: 15px;
}
.deliveryAddressLineInputgroup{
	height:152px;
	margin-bottom:15px;
}
.deliveryInputgroup{
	
	height:60px;
	margin-bottom:20px;
}
.deliveryPostalInputgroup{
	height:60px;
	margin-bottom:21px;
}
.deliveryCountyInputgroup{
	height:60px;
	margin-bottom:20px;
}
.deliveryAdditionalInputgroup{
	height:60px;
	margin-bottom:20px;
}
.newJobspecolone {
	margin-top:1.3%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: left;
	font-family: Verdana;
	font-size: 14px;
	max-height: 555px;
	/* line-height: 35px; */
}
.newJobspecolTwo {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	/* line-height: 30px; */
	font-family: Verdana;
	font-size: 14px;
	padding-top:2px;
	min-height: 619px;
}
.deliveryHeading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 100%;
	padding-left: 5px;
	padding-bottom: 15px;
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.newJobDelivery {
	display: flex;

	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	font-family: Verdana;
	font-size: 14px;
	min-height: 200px;
	
}
.deliveryDetails {
	padding-top: 8px;
}

/* .dateselector {
	background: transparent;
	border: none;
	border-bottom: 1px solid #000000;
	width: 250px;
} */
.FinisedSizebutton {
	width: 200px;
	text-align: left;
}
.newJobsubmitButtonRow {
	/* padding-top: 100px; */
	margin:0%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.updateJobNameInputGroup{
	height:82px;
	
}
.updateJobinputGroup{
	height:80px;
	margin-top:25px;
	
	
}
.recipientNameInputGroup{
	margin-top:2px;
	height:75px;
	margin-bottom:7px;
}
.otherRecipientNameInputGroup{
	padding-top: 2px;
	height:75px;
	
}
.updateJobSaveAndCancel {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 1%;
}
.updateJobCancelbutton{
	color: black;
	background-color: rgb(227, 225, 220, 1);

	border: none;
	font-size: 18px;
	/* width: 100%; */
	width: 100px;
    margin-right:1%;
	border-radius: 4px;
}
.newJobCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);

	border: none;
	font-size: 18px;
	/* width: 100%; */
	width: 100px;

	border-radius: 4px;
	/* padding: 3px; */
}
.newJobSavebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	border: none;
	font-size: 18px;
	/* width: 100%; */
	
	width: 100px;
	margin-right: 10%;
	border-radius: 4px;
}
option {
	text-align: left;
	font-size: 14px;
}
.finisedSizeOption{
	text-align: left;
	font-size: 12px;
}
.placeholder {
	font-size: 5px;
}
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 45px;
	height: 20px;
	padding-right: 50px;
	margin-top: 20%;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/******************Seller View/ Submit Bid Page*********************/
/* .sellerViewSubmit{
 color:#e6ebed;
 background-color: #ad9442;
 width: 50%;
 border: 2px solid black ;
} */
.specialCell {
	background-color: teal !important;
	font-weight: 500;
	color: white;
	width: 250px;
}
.sellersviewJobBids {
	width: 50px;
}
.sellerviewprojectName {
	color: rgb(0, 124, 132);
	font-size: 25px;
	padding-left: 30px;
	padding-top: 5px;
}
.sellerViewMajorRow {
	min-height: 300px;
	max-height: fit-content;
	padding: 13px 13px 0px 13px;
	font-size: 14px;


}
.sellersviewPageRow {
	min-height: 355px;
	max-height: fit-content;
	padding: 8px 8px 0px 8px;
	font-size: 15px;
	
	
}
.sellerViewProjectDelivery {
	max-height: 300px;
	line-height: 23px;
}
.sellerViewProjectSpecification{
	min-height: 250px;
}
.sellerViewProjectGeneral{
	min-height: 250px;
}
.value{
	max-width: 300px;
}
.dateFieldValue{
	width: 200px;
	margin-left:5%;
}
.fieldName{
	font-weight:bold;
}
.spec{
	max-width: 100px;
}
.sellersviewPageTable {
	height: 10px;
	
}
.sellersviewPageTable tr,
.sellersviewPageTable td {
	line-height: 16px;
}
.sellersviewPageHeadingName {
	width: 400px;
}
/* .sellersviewPageHeadingQty {
	max-width: 10px;
} */

.sellerviewthead {
	background-color: rgb(0, 124, 132);
	color: white;
	text-align: left;
}
.sellerviewthead th {
	font-weight: 500;
	font-size: 17px;
}
.sellersviewPageHeading {
	background-color: rgb(0, 124, 132) !important;
	color: white;
	font-weight: 500;
	font-size: 15px;
}
.sellersviewPageTable .CostQuantity {
	width: 20%;
}
.sellersviewPageTable .qty {
	width: 10%;
}
.sellerViewSubmitTab {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
   
	
}
.sellerViewSubmit {
	color: white;
	background-color: rgb(173, 148, 66);
	float: center;
	border: none;
	font-size: 18px;
	width: 85%;
	margin-top: 30px ;
	border-radius: 4px;
	padding: 25px;
	margin-left:5%
}
.sellerViewCancel {
	color: black;
	background-color: rgba(218, 218, 218, 1);
	float: center;
	border: none;
	font-size: 18px;
	margin-bottom:20px ;
	width: 85%;
	border-radius: 4px;
	padding: 25px;
	margin-left:5%
}
.sellerviewvertical {
	border-left: 1px solid rgb(69, 137, 137);
	height: 230px;
	position: absolute;
	left: 46%;
}
/*********Loader************/
.createNewJobLoader,.updateJobLoader{
	text-align: center;
	padding: 400px 220px 316px 220px;
}
.ViewJobLoader{
	padding: 180px;
	padding-left: 900px;
}
.sellerViewJobsLoader{
	padding-top:200px;
	padding-bottom: 197px;
	padding-left: 880px;
}
.ViewProjectLoader {
	padding-top:210px;
	padding-left: 900px;
}
.bidResponseLoader {

	padding: 30px;
	padding-left: 910px;
}
.BuyerUpdateJobLoader{
     margin-top:1%;
	padding-left:890px;
}
.buyerUpdateProjectLoader{
margin: 10% 0% 9.2% 0%;
	padding-left: 913px;
}
.sellerJobTable tr {
	line-height: 8px;
}
.createProjectLoader{
	text-align: center;
	padding: 400px 220px 400px 220px;
}
/******************Seller view Page ************************/
.listOfJobLoader {
	padding: 15px;
	padding-left: 900px;
}

.sellerViewJob {
	padding-left: 35px;
	padding-right: 20px;
	font-size: 14px;
}
.JobsHeading {
	color: rgb(0, 124, 132);
	font-size: 25px;
	font-weight: 400;
}
.sellerViewPageRow {
	margin-top: 0.5%;
	padding-left: 20px;
	padding-right: 25px;
}

.sellerviewjobName {
	width: 15%;
}
.sellerviewQuoterequiredby,
.sellerviewtablepdfavailability,
.sellerviewtabledeliverydate,
.sellerviewtablestatus {
	width: 15%;
}
.sellerviewApprover {
	width: 10%;
}
.sellerprojectDetails {
	padding: 10px;
	font-size: 15px;
}

.btn-link {
	padding: 0;
	font-size: 14px;
}
.bidReferenceLink {
	color: #008080;
}
/************************* Bid Costing ***********************/

.bidCostingContainer {
	padding: 15px 20px 0px 20px;
	margin: 1%;
	box-sizing: border-box;
	text-align: center;
	box-shadow: 0px 0px 10px 10px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
	max-height: fit-content;
	width: 40%;
	min-height: 450px;
	font-weight: 300;
	font-family: Verdana;
	line-height: 25px;
	font-size: 20px;
}
.Heading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 100%;
	padding-top: 3px;
	padding-left: 2px;
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.BidCostingInlineData {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
	font-family: Verdana;
	line-height: 40px;
	height: 43px;
	/* padding-left: 10px; */
}
.declinedReasonInputGroup{
	height: 135px;
}
.BidCostingRequiredField {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
	font-family: Verdana;
	line-height: 40px;
}
.bidCostingInputValues {
	width: 39%;
	text-align: right;
	padding-right: 10px;
	height: 30px;
	border-radius: 5px;
	border: 1px solid rgb(176, 172, 172);
}
.requiredBidCostInput {
	text-align: right;
}
/* .displayFlex{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.reqBidCostingReqInputValues {
	width: 140px;
text-align: right;
	padding-right: 10px;
	height: 30px;
	border-radius: 5px;
	line-height: 10px;
	font-size: 14px;
	border: 1px solid rgb(176, 172, 172);
}
.form-control,
.dropdownbtn {
	font-size: 14px;
}
.bidCostingReqInputValues {
	line-height: 10px;
	width: 80%;
	padding-left: 50px;
	padding-right: 5px;
	height: 30px;
	border-radius: 5px;
	border: 1px solid rgb(176, 172, 172);
}
.bidExpiryDate {
	background: transparent;
	border: none;
	border-bottom: 1px solid #000000;
	width: 145px;
}
.BidCosting {
	font-size: 14px;
	font-family: Verdana;
	text-align: left;
	line-height: 30px;
}
.bidCostingSubmitRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
}
.bidCostingSave {
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 16px;
	width: 15%;
	margin: 1%;
	border-radius: 4px;
	padding: 4px;
}
.bidCostingCancel {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 16px;
	width: 15%;
	margin: 1%;
	border-radius: 4px;
	padding: 4px;
}
.bidCostingHeading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 726%;
	padding-top: 3px;
	padding-left: 2px;
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
/**********bid cost submit**********/
.ConfirmSupplierCloseButton{
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 16px;
	width: 18%;
margin-top: 10px;
	border-radius: 4px;
	padding: 4px;
	
}
.bidSubmitConfirmationHeading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 210%;
	padding-top: 3px;
	padding-left: 2px;
	text-align: left;

	border-bottom: 2px solid rgb(0, 124, 132);
}
.bidSubmitConfirmButton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 16px;
	width: 16%;
	border-radius: 4px;
	padding: 3px;
}
.bidSubmitCancelButton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 16px;
	width: 15%;

	border-radius: 4px;
	padding: 4px;
}
.bidSubmitErrorInfo {
	text-align: left;
	color: rgb(207, 60, 74);
	font-size: 18px;
	font-family: Verdana;
	font-weight: 100;
}
.bidSubmitError {
	font-family: Verdana;
	font-weight: 300;
	font-size: 18px;
}
.bidSubmitInfo {
	text-align: center;
	font-size: 20px;
	line-height: 33px;
}
.bidSubmitProjectInfo {
	color: #008080;
}
.bidSubmitModalBox {
	/* padding: 15px 20px 0px 20px; */
	/* margin: 1%; */
	box-sizing: border-box;
	/* text-align: center; */
	background: rgb(245, 245, 245);
	max-height: fit-content;
	width: 100%;
	min-height: 100px;
	font-weight: 300;
	font-family: Verdana;
	line-height: 25px;
	font-size: 20px;
}
/*********Seller Project List*************/
.NoProjectDetails {
	text-align: left;
    padding-top: 5px;
	padding-left:5px;
	font-size: 15px;
	font-family: verdana;
}
.NoSupplierDetails,
.NoQtyDetails {
	text-align: left;
	padding: 10px 10px 30px 10px;
	font-size: 15px;
	font-weight: 100;
	font-family: verdana;
	color: black;
}
.sellerSearchButton {
	color: white;
	background-color: rgb(0, 124, 132);
	float: left;
	border: none;
	font-size: 18px;
	width:75px;
	height: 34px;
	margin-top:25px;

	border-radius: 4px;
	padding: 5px;
}
.sellerProjectList {
	padding-left: 20px;
}
.sellerlistOfProjectstable {
	min-height: 640px;
	
}
.sellerlistOfProjectstable tr {
	line-height: 10px;
}
.sellerProjectListsearchTab {
	display: flex;
	flex-direction: row;
}
.sellerlistOfProjectstable {
	padding: 15px;
	max-height: fit-content;
	font-size: 14px;
	font-family: Verdana;
}

/******************** manage Bid Selected ******************/
.manageBidMajorBid {
	/* padding: 10px 10px 10px 10px; */
	margin:5px ;
}
.qtyCellValue{
	height:10px;
}
.BidSelectionConfirmationHeading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 190%;
	padding-top: 3px;
	padding-left: 2px;
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.manageBidSelectedbox {
	padding: 15px 20px 0px 20px;
	margin: 1%;
	box-sizing: border-box;
	text-align: center;
	/* box-shadow: 0px 0px 10px 10px rgb(233, 226, 226); */
	background: rgb(245, 245, 245);
	max-height: fit-content;
	width: 100%;
	min-height: 200px;
	font-weight: 300;
	font-family: Verdana;
	line-height: 25px;
	font-size: 20px;
}
.Heading {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width: 100%;
	padding-top: 3px;
	padding-left: 2px;
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.Suppliername {
	color: rgb(0, 124, 132);
	font-weight: 200;
	font-family: Verdana;
	font-size: 20px;
}
.projectName {
	font-size: 20px;
	color: rgb(0, 124, 132);
	font-weight: 300;
	font-family: Verdana;
}
.manageBidSelectnote {
	font-size: 15px;
	padding-top: 10px;
}
.manageBidSelectedsubmitButtonrow {
	float: center;
	padding: 15px;
}
.manageBidSelectedCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: center;
	border: none;
	font-size: 18px;
	width: 20%;
	margin: 1%;
	border-radius: 4px;
	padding: 5px;
}
.manageBidSelectedSavebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: center;
	border: none;
	font-size: 18px;
	width: 18%;
	margin: 1%;
	margin-right: 3%;
	margin-left: 2%;
	border-radius: 4px;
	padding: 5px;
}
/*****************manage Bid Selection******************/
.BidSubmitConfirmationbox {
	padding: 15px 20px 0px 20px;
	margin: 1%;
	box-sizing: border-box;
	text-align: center;
	box-shadow: 0px 0px 10px 10px rgb(233, 226, 226);
	background: rgb(245, 245, 245);
	max-height: fit-content;
	width: 30%;
	min-height: 200px;
	font-weight: 300;
	font-family: Verdana;
	line-height: 25px;
	font-size: 20px;
}
.bidSubmitConfirmationsubmitButtonrow {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 15px;
}
.bidsubmitConfirmationSavebutton {
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 18px;
	width: 15%;
	margin: 1%;
	margin-right: 3%;
	margin-left: 2%;
	border-radius: 4px;
	padding: 5px;
}
.bidsubmitConfirmationCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	width: 15%;
	margin: 1%;
	border-radius: 4px;
	padding: 5px;
}

/************* Sign Up *******************/
.signUpErrorMsg{
	color:red;
}
.SignupErrorInfo{
	font-family: Verdana;
	font-size: 18px;
	font-weight: 200;
	color: rgb(0, 124, 132);
	background-color: rgba(218, 218, 218, 1);
	height: 30px;
	width:1000%;
	padding-top: 5px;
	
	text-align: left;
	/* box-shadow:0px 11px 11px -11px rgb(0,124,132) ; */
	border-bottom: 2px solid rgb(0, 124, 132);
}
.signUpCol {
	margin: 0% 30% 0% 25%;
}
.signUpHeading {
	color: #008080;
	margin: 2%;
	font-weight: 200;
	font-size: 25px;
	font-family: Verdana;
}
.SignUpform {
	text-align: left;
	font-size: 15px;
	color: rgb(6, 6, 6);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: 125px;
	line-height: 25px;
	padding-top:10px
}
.SignUpformFlexItem {
	padding-bottom: 10px;
	width: 600px;
	margin-right: 10%;
	
}
.signUpButton {
	color: white;
	float: right;
	margin-right: 40%;
	width:119%;
	border: 1px solid rgb(162, 176, 173);
	border-radius: 5px;
	padding: 5px 15px 5px 15px;
	background-color: rgb(173, 148, 66);
	margin-bottom:1%;
}
.SignUpButtonRow {
	margin-top: 3%;
	margin-left: 37%;
}
.signupUserName {
	font-size: 12px;
	font-family: verdana;
	font-weight: 100;
}
.signUpInputGroup{
	height:85px;
	margin-top:5px;
}
.updateJobInputGroup{
	height:76px
}

/******************* verifyconfirmationpage **************/

.VerifyConfirmationHeading {
	color: #008080;
	margin: 4%;
	font-weight: 200;
	font-size: 25px;
	font-family: Verdana;
}
.verificationCodeTab {
	width: 300px;
}
.registerEmail {
	color: #008080;
}
.d-flex {
	width: 600px;
	padding-left: 250px;
}
.code {
	margin: 2%;
}
.verifyButton {
	color: white;
	border: 1px solid rgb(162, 176, 173);
	border-radius: 5px;
	width: 80px;
	float: right;
	margin-top: 5%;
	margin-right: 35%;
	padding: 5px 15px 5px 15px;
	background-color: rgb(173, 148, 66);
}
/***********buyer update job*******/
.newJobMajorRow {
	padding: 0px 10px 0px 10px;

}

.projectDetailsAreaRowOne {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 251px;
	
	line-height: 20px;
	justify-content: space-around;
	margin-bottom:2%

}
.projectDetailsAreaRowTwo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 365px;
	/* padding-top: 5px; */
	line-height: 20px;
	justify-content: space-around;
}
.noJobs {
	text-align: left;
	font-size: 15px;
	font-family: Verdana;
	width: 900px;
	height: 30px;
}
/**********update project********/

.BuyerJobData {
	font-size: 14px;
	font-family: Verdana;
	font-weight: 200;
}
.projectCompletionDate {
	font-size: 14px;
}
.CreateNewJobTab {
	margin: 1% 0% 0.5% 0%;
}
.PageNotFound{
	font-size: 18px;
	padding-left:10px;
	padding-top: 10px;;
}
/**********erroe Page**********/
.errMsg{
	font-size: 17px;
	padding-left:10px;
	padding-top: 10px;;
}
/*********Buyer Create Job*********/
.createJobMajorRow{
	padding: 0px 10px 0px 10px;
}
/**********SignUp************/
.SignUpCloseButton{
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 16px;
	width: 15%;

	border-radius: 4px;
	padding: 4px;
}
.SignUpLink{
	color: #008080;
	margin-right: 30%;
}
.backToLogin{
	margin-left:22%;
}

.BidResponseHeading{
	background-color: #008080 !important;
}
/*****************ISISBN in Update/create project***********/
.isIsbnInlineData {
	padding-top: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 110px;;
}
.isbnInputGroups{
	max-height: fit-content;
}

.NewProjectCol2BuyerInputGroup{
	padding-top: 2px;
	height:95px;
}
.NewProjectCol2ProjectCompletionDateInputGroup,.NewProjectCol2ClientReferenceInputGroup,.NewProjectCol2ClientInputGroup{
	height:90px;
}
.NewProjectCol2ISBNImpressionInputGroup,.NewProjectCol2ISBNTitleInputGroup{
	height:85px;
}

.NewProjectCol2ISBNInputGroup{
	height:90px;
}
.NewProjectCol1TitleInputGroup{
	height:70px;
	
}
.updateProjectSubmitButtonRow{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
}
.updateProjectSavebutton{
	color: white;
	background-color: rgb(173, 148, 66);
	float: right;
	border: none;
	font-size: 18px;
	margin:0% 2% 0% 0%;
	width: 4%;
	height:30px;
	border-radius: 4px;
	padding: 2px;
}
.updateProjectCancelbutton {
	color: black;
	background-color: rgb(227, 225, 220, 1);
	float: right;
	border: none;
	font-size: 18px;
	margin:0% 1.5% 0% 0%;
	width: 5%;
	height:30px;
	border-radius: 4px;
	padding: 2px;
}
.updateProjectRow {
	padding-left: 20px;
	padding-right: 20px;
	
}